.App {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 800px;
    height: 100vh;
    font-family: 'Pollock2CTT';
}

@media (min-width: 1367px) and (max-width: 1919px) {

}

@media (min-width: 1281px) and (max-width: 1366px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 768px) and (max-width: 1024px) {

}

@media (min-width: 320px) and (max-width: 767px) {
    .App {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100vh;
    }
}