.menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;


    font-size: 20px;


    & .title {
        font-weight: bold;
    }

    & .exit {
        text-align: center;
        font-weight: bold;
    }

    & .price {
        text-align: right;
        font-weight: bold;
    }

    & .header {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: stretch;
        align-items: center;

        & > .logo {
            width: 50%;
            height: auto;
        }
    }

    &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        width: 50%;
        text-align: center;

        color: saddlebrown;
        text-transform: uppercase;
        font-size: xxx-large;
        word-break: break-word;
    }

    & .content {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        overflow-y: auto;

        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none;  /* IE 10+ */

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent; /* Chrome/Safari/Webkit */
        }
    }

    & .row {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: center;
        width: calc(100% - 40px);
        margin-right: 20px;
        margin-left: 20px;
        margin-bottom: 20px;

        &-title {
            border-bottom: 2px solid #8b4513;
            padding-bottom: 10px;
        }

        &.sub_title {
            margin-top: 30px;
            border-bottom: 2px solid #8b4513;
            & .title {
                color: #8b4513;
                font-size: 32px;
                padding-bottom: 20px;
                font-style: italic;
            }
        }

        & .col {
            display: block;
            width: 30%;
        }

        & .col-2 {
            display: block;
            width: 20%;
        }

        & .col-6 {
            display: block;
            width: 60%;
        }

        & .col-4 {
            display: block;
            width: 40%;
        }
    }
}

@media (min-width: 1367px) and (max-width: 1919px) {

}

@media (min-width: 1281px) and (max-width: 1366px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (min-width: 768px) and (max-width: 1024px) {
    .menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        font-size: 18px;


        & .title {
            font-weight: bold;
        }

        & .exit {
            text-align: center;
            font-weight: bold;
            word-break: break-word;
        }

        & .price {
            text-align: right;
            font-weight: bold;
        }

        & .logo {
            width: 50%;
            height: auto;
        }

        &-header {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: xxx-large;
        }

        & .content {
            width: 100%;
            height: 100%;
            overflow-y: auto;

            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* IE 10+ */

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* Chrome/Safari/Webkit */
            }
        }

        & .row {
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            width: calc(100% - 40px);
            margin-right: 20px;
            margin-left: 20px;
            margin-bottom: 20px;

            & .col {
                display: block;
                width: 30%;
            }

            & .col-2 {
                display: block;
                width: 20%;
            }

            & .col-6 {
                display: block;
                width: 55%;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        position: relative;

        font-size: 16px;


        & .title {
            font-weight: bold;
        }

        & .exit {
            text-align: center;
            font-weight: bold;
            word-break: break-word;
        }

        & .price {
            text-align: right;
            font-weight: bold;
        }

        & .header {
            & > .logo {
                width: 50%;
                height: auto;
                padding-left: 10px;

                & .logo {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        &-header {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: xx-large;
        }

        & .content {
            width: 100%;
            height: 100%;
            overflow-y: auto;

            scrollbar-width: none; /* Firefox */
            -ms-overflow-style: none;  /* IE 10+ */

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent; /* Chrome/Safari/Webkit */
            }
        }

        & .row {
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            width: calc(100% - 40px);
            margin-right: 20px;
            margin-left: 20px;
            margin-bottom: 20px;

            & .col {
                display: block;
                width: 30%;
            }

            & .col-2 {
                display: block;
                width: 20%;
            }

            & .col-6 {
                display: block;
                width: 60%;
            }
        }
    }
}