.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;

    & .logo {
        display: block;
        width: 80%;
        height: auto;
        margin: 0 auto;
    }

    & h2 {
        color: saddlebrown;
        text-transform: uppercase;
        font-size: 64px;
        text-align: center;
    }

    &-nav {
        margin-bottom: 100px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        & a {
            display: block;
            margin-right: 50px;
            font-size: 40px;
            background-color: saddlebrown;
            padding: 15px 30px;
            border-radius: 30px;
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
            text-decoration: none;
            color: #fff;


            &:nth-last-child(1) {
                margin-right: 0;
            }
        }
    }
}

@media (min-width: 1367px) and (max-width: 1919px) {

}

@media (min-width: 1281px) and (max-width: 1366px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) {
    .main {
        width: 100%;
        height: 100%;

        & .logo {
            padding-left: 0;
        }

        & h2 {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: 64px;
            text-align: center;
        }

        &-nav {
            margin-bottom: 250px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            & a {
                display: block;
                margin-right: 50px;
                font-size: 32px;
                background-color: saddlebrown;
                padding: 15px 30px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;


                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .main {
        width: 800px;
        height: 100%;

        & .logo {
            padding-left: 0;
        }

        & h2 {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: 64px;
            text-align: center;
        }

        &-nav {
            margin-bottom: 50px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            & a {
                display: block;
                margin-right: 25px;
                margin-bottom: 0;
                font-size: 32px;
                background-color: saddlebrown;
                padding: 15px 30px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;


                &:nth-last-child(1) {
                    margin-right: 100px;
                }
            }
        }
    }
}

@media (min-width: 414px) and (max-width: 767px) {
    .main {
        width: 100%;
        height: 100%;

        & .logo {
            padding-left: 0;
        }

        & h2 {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: 64px;
            text-align: center;
        }

        &-nav {
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            & a {
                display: block;
                margin-right: 0;
                margin-bottom: 25px;
                font-size: 32px;
                background-color: saddlebrown;
                padding: 15px 30px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;


                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 413px) {
    .main {
        width: 100%;
        height: 100%;

        & .logo {
            padding-left: 0;
        }

        & h2 {
            color: saddlebrown;
            text-transform: uppercase;
            font-size: 64px;
            text-align: center;
        }

        &-nav {
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            & a {
                display: block;
                margin-right: 0;
                margin-bottom: 25px;
                font-size: 32px;
                background-color: saddlebrown;
                padding: 15px 30px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;


                &:nth-last-child(1) {
                    margin-right: 0;
                }
            }
        }
    }
}