.titles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;

    & .logo {
        display: block;
        width: 80%;
        height: auto;
        margin: 0 auto;

        & .logo {
            padding-left: 0;
        }
    }

    &-header {
        display: inline-block;
        position: absolute;
        left: 250px;

        color: saddlebrown;
        text-transform: uppercase;
        font-size: 52px;
    }

    &-nav {
        margin-bottom: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 25px;

        & a {
            display: block;
            margin-bottom: 25px;
            margin-right: 25px;
            font-size: 24px;
            background-color: saddlebrown;
            padding: 15px 30px;
            border-radius: 30px;
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
            text-decoration: none;
            color: #fff;
        }
    }
}

@media (min-width: 1367px) and (max-width: 1919px) {

}

@media (min-width: 1281px) and (max-width: 1366px) {

}

@media (min-width: 1025px) and (max-width: 1280px) {

}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

@media (min-width: 768px) and (max-width: 1024px) {
    .titles {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 800px;
        height: 100%;
        position: relative;
        overflow: hidden;

        &-header {
            display: inline-block;
            position: absolute;
            left: 250px;
            text-align: center;

            color: saddlebrown;
            text-transform: uppercase;
            font-size: 52px;
        }

        &-nav {
            margin-top: 50px;
            margin-bottom: 150px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            padding-bottom: 25px;
            padding-left: 15px;
            padding-right: 15px;

            & a {
                display: block;
                margin-bottom: 25px;
                margin-right: 25px;
                font-size: 24px;
                background-color: saddlebrown;
                padding: 15px 30px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;
            }
        }
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .titles {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &-header {
            display: inline-block;
            position: absolute;
            left: 150px;
            text-align: center;

            color: saddlebrown;
            text-transform: uppercase;
            font-size: 36px;
        }

        &-nav {
            margin-top: 50px;
            margin-bottom: 100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            padding-bottom: 25px;
            padding-left: 15px;
            padding-right: 15px;

            & a {
                display: block;
                margin-bottom: 25px;
                margin-right: 25px;
                font-size: 18px;
                background-color: saddlebrown;
                padding: 10px 20px;
                border-radius: 30px;
                box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                text-decoration: none;
                color: #fff;
            }
        }
    }
}